import './App.css';
import { useState } from 'react';
import QRCode from "react-qr-code";

function App() {

  const [url, setUrl] = useState('');

  const updateUrl = (event) => {
    setUrl(event.target.value);
  }

  const downloadQR = () => {
    const qr = document.getElementById("qrcode");
    if (!qr) {
      return console.error("Something went wrong")
    }
    
    const svgData = new XMLSerializer().serializeToString(qr)
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    const img = new Image()
    img.onload = function () {
      canvas.width = img.width + 40
      canvas.height = img.height + 40
      ctx.strokeRect(0, 0, canvas.width, canvas.height)
      ctx.fillStyle = "#FFFFFF"
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(img, 20, 20)
      const pngFile = canvas.toDataURL("image/png", 1.0)

      const downloadLink = document.createElement("a")
      downloadLink.download = "qrcode"
      downloadLink.href = `${pngFile}`
      downloadLink.target = "_blank"
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)

      downloadLink.remove()
    }

    img.src = "data:image/svg+xml;base64," + btoa(svgData)
  };

  return (
    <div className="App">
      <div id="inputDiv">
        <input onChange={updateUrl} value={url} placeholder='Insert an URL or text' />
      </div>
      <div id="imageDiv">
        {(url !== '') ? <QRCode
          id='qrcode'
          value={url}
        /> : null}
      </div>
      <div id="buttonDiv">
        {(url !== '') ? <button onClick={downloadQR}>Download</button> : null}
      </div>
    </div>
  );
}

export default App;